.genre {
    margin: 2em .5em;
    text-align: center;
    color: wheat;


    h2 {
        border-radius: .25em;
        background-color: orange;
        color: black;
        cursor: pointer;

    }

    h2:hover {
        transform: scale(1.15);
        box-shadow: 2px 5px black;
    }

}