.movies {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

    h1 {
        color: #ffa726;
        text-align: center;
        margin-top: 12.5%;
    }
}

.fetching {
    text-align: center;
    font-size: 5em;
}

.searchedFor {
    h1 {
        text-align: center;
        color: orange;
    }

    h2 {
        color: wheat;
    }

}



.selectDropdown {
    display: flex;
    justify-content: flex-end;
    margin: 1em;

    select {
        width: 17.5em;
        border-radius: .5em;
        padding: 1em;
        border: none;
        font-weight: bolder;
        color: wheat;
        background-color: rgba(137, 137, 137, 0.242);
    }

    select:hover {
        background-color: white;
        color: orange;
    }
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search {
        margin: 1em;

    }

    #outlined-basic {
        background-color: rgba(240, 248, 255, 0.25);
        border-radius: .25em;
        color: #ffa726;
        width: 20em;
        min-width: 2em;
    }
}

.genreAndMovies {
    display: flex;
}

#selectedGenreName {
    color: wheat;
    margin-left: 7em;
    margin-bottom: -.5em;

}


.blur-in-expand-genre {
    animation: blur-in-expand-genre 1.25s ease-in-out both
}

@keyframes blur-in-expand-genre {
    0% {
        transform: scale(0);
        filter: blur(100px);
        opacity: 0
    }

    100% {
        transform: scale(1);
        filter: blur(0);
        opacity: 1
    }
}