.movie {
    background-color: rgba(72, 130, 224, 0.05);
    color: wheat;
    padding: 2em;
    margin: 2em;
    border-radius: 2em;
    transition: all .25s ease-in-out;

    img {
        width: 20em;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 2em;
        border: 3px solid orange;
    }

    p {
        font-size: 1.25em;
    }

}


.movieTitleVoteDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.date {
    font-style: italic;
    font-weight: 1;
    font-size: 1.5em;
}

.vote {
    font-size: 2.5em;
}

#overview {
    text-align: center;
}

.movie:hover {
    transition: all .25s ease-in-out;
    box-shadow: 0 0 5px 5px orange;

}


.blur-in-expand {
    animation: blur-in-expand 1s ease-in-out both
}

@keyframes blur-in-expand {
    0% {
        transform: scale(0);
        filter: blur(12px);
        opacity: 0
    }

    100% {
        transform: scale(1);
        filter: blur(0);
        opacity: 1
    }
}