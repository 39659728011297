.footer {
    background-color: orange;
    margin-bottom: -5em;
    padding: 0;
    text-align: center;

}


.footerFlex {
    padding-top: 1em;
}