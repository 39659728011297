.movieContainer {
    color: wheat;
}

#homepage {
    text-decoration: none;
    color: orange;
    margin: 2em 0 !important;
}

.pills {
    background-color: orange;
    border-radius: 2em;
    padding: .25em;
    margin: .5em;
    font-size: 1.25em;
}

#posterIMG {
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    opacity: .2;
    margin-bottom: -17em;
    width: 100%;
    height: 80vh;
    object-fit: cover;


}


.FlexCont {
    //multiple divs
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin: 2em 0;

    .flex {
        border: 2px solid orange;
        border-radius: 2em;
        padding: 2em;
        padding-bottom: 4em;
    }

    h2 {
        border: 2px solid orange;
        border-radius: 2em;
        padding: 1em;
    }
}

.description {
    margin: 3em 2em;
    text-align: center;
    transform: translateY(-30vh);

    .originalTitle {
        font-size: 8em;
        margin: 0;
    }

    /* Changing the font size of the original title. */

    .largeTitle {
        margin-top: 1.25em;
        font-size: 5em !important;
    }
}

.overviewAndOrigianlTitle {
    margin: 3em 2em;

    #overview {
        line-height: 2em;
        font-size: 1.5em;
        text-align: left;
    }

    h1 {
        text-align: center;
        border: 2px solid orange;
        border-radius: 2em;
        padding: .5em;
    }
}





















.imgAndGenre {
    display: flex;
    justify-content: space-around;

    img {
        border-radius: 2em;
        border: 2px solid orange;
    }

}

#homepage {
    display: block;
    margin: 1em 0;
    font-size: 2em;
}

.rating {
    font-size: 1.5em;
}