@media all and (min-width:300px) and (max-width:1100px) {
    .movieContainer {

        .description {
            margin: 4em 2em;
        }

        .description .originalTitle {
            font-size: 4em;
        }

        .description .largeTitle {
            font-size: 3.5em !important;

        }

        .FlexCont {
            flex-direction: column;

            .flex {
                margin: 1em;
            }
        }

        .pills {
            display: flex;
            text-align: center;
        }

        .imgAndGenre {
            flex-direction: column;
            text-align: center;
        }
    }
}